<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4> Check-in/out</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ Check-in/out
                                </div>
                            </div>
                            <div class="breadcrumb-left" v-if="user">
                                <h6>RFID:- {{user.rfid_id || 'N/A'}}</h6>
                                <div class="breadcrumb-sub-header">
                                  <h6>RFID expiry date:- {{user.rfid_expiry_date || 'N/A'}}</h6>
                                  <h6>
                                    RFID status:-  <span class="badge  text-lg"
                                                           v-bind:class="{
                                                    'badge-success': user.rfid_expiry_status == 'RFID Card issued', 'badge-warning': user.rfid_expiry_status == 'RFID Card not issued' ,
                                                    'badge-info': user.rfid_expiry_status == 'RFID Card Expiring soon',  'badge-danger': user.rfid_expiry_status == 'RFID Card Expired',
                                              }">
                                                {{ user.rfid_expiry_status }}
                                              </span>
                                    </h6>
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <v-btn class="mt-4 btn btn-primary" style="color: #fff" @click="addAttendance" v-if="!user && checkIsAccessible('rfid-card', 'create')">
                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                                        Add new
                                    </v-btn>
                                    <v-btn class="mt-4 btn btn-primary" style="color: #fff" @click="addRFID" v-if="user && checkIsAccessible('rfid-card', 'create')">
                                        {{user.rfid_id ? "Update" : "Add"}}  RFID
                                    </v-btn>
                                    <b-dropdown v-if="checkIsAccessible('import-export', 'rfid-card-export')" size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
                                      <template v-slot:button-content>
                                        <i class="ki ki-bold-more-hor"></i>
                                      </template>
                                      <!--begin::Navigation-->
                                      <div class="navi navi-hover min-w-md-250px">
                                        <b-dropdown-text tag="div" class="navi-item">
                                          <a @click="exportCsv()"  class="navi-link">
                                            <span class="navi-icon">
                                                <i class="fa fa-file-csv"></i>
                                            </span>
                                            <span class="navi-text">Export (CSV)</span>
                                          </a>
                                        </b-dropdown-text>
                                      </div>
                                      <!--end::Navigation-->
                                    </b-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" md="3" v-if="!user">
                                    <v-select
                                        outlined
                                        dense
                                        label="User type"
                                        :items="userTypes"
                                        v-model="search.user_type"
                                        item-text="name"
                                        item-value="value"
                                        clearable
                                        v-on:keyup.enter="searchAttendanceReports()"
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-select
                                        :items="academicYears"
                                        class="form-control"  :menu-props="{ button: true, offsetY: true }"
                                        v-model="search.academic_year_id"
                                        label="Academic year"
                                        item-value="id"
                                        item-text="year"
                                        outlined  v-on:keyup.enter="searchAttendanceReports()"
                                        clearable
                                        dense>
                                    </v-select>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-select
                                        :items="levels"  :menu-props="{ button: true, offsetY: true }"
                                        class="form-control"
                                        v-model="search.level_id"
                                        label="Level"
                                        item-value="id"
                                        item-text="title"
                                        outlined 
                                        v-on:keyup.enter="searchAttendanceReports()"
                                        @change="getPrograms"
                                        clearable
                                        dense>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select outlined dense
                                        v-model="search.program_id"
                                        :items="programs"
                                        label="Programs"
                                        item-value="id"  
                                        :menu-props="{ button: true, offsetY: true }"
                                        item-text="title"
                                        @change="getGrades" 
                                        v-on:keyup.enter="searchAttendanceReports()"
                                        clearable>
                                    </v-select>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-select 
                                        outlined 
                                        dense
                                        v-model="search.grade_id"
                                        :items="gradesLevels"
                                        label="Semester/year"  
                                        :menu-props="{ button: true, offsetY: true }"
                                        item-value="id"
                                        item-text="title" 
                                        v-on:keyup.enter="searchAttendanceReports()"
                                        @change="getAcademicClasses"
                                        clearable
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-select 
                                        outlined 
                                        dense
                                        class="form-control"
                                        v-model="search.class_id"
                                        :items="academicClasses"  
                                        :menu-props="{ button: true, offsetY: true }"
                                        label="Class name"
                                        item-value="id" v-on:keyup.enter="searchAttendanceReports()"
                                        item-text="title"
                                        clearable
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-text-field 
                                        class="form-control" 
                                        v-model="search.user"
                                        label="Name, email, or CRN" 
                                        outlined 
                                        clearable
                                        v-on:keyup.enter="searchAttendanceReports()" 
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field 
                                        class="form-control" 
                                        v-model="search.subject"
                                        label="Subject" 
                                        outlined 
                                        clearable
                                        v-on:keyup.enter="searchAttendanceReports()" 
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select
                                        outlined
                                        dense
                                        label="Attendance type"
                                        :items="types"
                                        v-model="search.type"
                                        item-text="name"
                                        item-value="value"
                                        v-on:keyup.enter="searchAttendanceReports()" 
                                        clearable
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-menu
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        min-width="auto"
                                        offset-y
                                        transition="scale-transition"
                                        v-model="menu"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            append-icon="mdi-calendar"
                                            clearable
                                            dense
                                            label="From"
                                            outlined
                                            readonly 
                                            v-on:keyup.enter="searchAttendanceReports"
                                            v-bind="attrs"
                                            v-model="search.entry_date"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        @input="menu = false"
                                        v-model="search.entry_date"
                                    ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                
                                <v-col cols="12" md="3">
                                    <v-menu
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        min-width="auto"
                                        offset-y
                                        transition="scale-transition"
                                        v-model="menu1"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            append-icon="mdi-calendar"
                                            clearable
                                            dense
                                            label="To"
                                            outlined
                                            readonly 
                                            v-on:keyup.enter="searchAttendanceReports"
                                            v-bind="attrs"
                                            v-model="search.exit_date"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        @input="menu1 = false"
                                        v-model="search.exit_date"
                                    ></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <!-- <v-col cols="12" md="3">
                                    <v-select
                                    outlined
                                    dense
                                    label="Report type"
                                    :items="reportIntervals"
                                    item-text="name"
                                    item-value="value"
                                    ></v-select>
                                </v-col> -->

                                <v-col cols="12" :md="!user ? 6 : 12" class="text-right">
<!--                                    <v-btn -->
<!--                                    :loading="isExportingCsv" -->
<!--                                    @click="exportCsv()"-->
<!--                                    class="btn btn-primary ml-4 w-35 float-right" -->
<!--                                    dark-->
<!--                                    >-->
<!--                                    <v-icon small elevation="2" outlined>fas fa-file-csv</v-icon>&nbsp;-->
<!--                                    Export CSV-->
<!--                                    </v-btn>-->

                                    <v-btn 
                                      :loading="loading"
                                      @click.prevent="searchAttendanceReports()"
                                      class="btn btn-primary w-35"
                                      dark
                                    >
                                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                    Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>

                        <div class="">
                            <table class="table">
                                <thead>
                                <tr class="px-3">
                                    <th class="px-3 wrap-column">User</th>
                                    <th class="px-3 wrap-column">Type</th>
                                    <th class="px-3 wrap-column">Check-in time</th>
                                    <th class="px-3 wrap-column">Check-out time</th>
                                    <th class="px-3 wrap-column">Device</th>
                                    <th class="px-3 wrap-column">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-show="generalAttendances.length > 0"
                                    v-for="(item, index) in generalAttendances"
                                    :key="index"
                                >
                                    <td class="px-3 wrap-column">
                                        <div>
                                            <a href="#" @click="studentSummary( item.user_id)" class="mr-2">
                                            {{ item.user_full_name }}
                                            </a>
                                        </div>
                                        <div class="mt-2">
                                            <strong>CRN: </strong>
                                            <a href="#" @click="studentSummary( item.user_id)">{{ item.user_crn }}</a>
                                        </div>
                                      <div class="mt-3">
                                        <strong>RFID: </strong>
                                        {{ item.rfid_id ? item.rfid_id : 'NA' }}
                                      </div>
                                    </td>
                                    <td class="px-3 wrap-column">
                                        {{ item.type_formatted }}
                                    </td>
                                    <td class="px-3 wrap-column">
                                    {{ item.entry_timestamp ? item.entry_timestamp : '-' }}
                                    </td>
                                    <td class="px-3 wrap-column">
                                    {{ item.exit_timestamp ? item.exit_timestamp : '-' }}
                                    </td>
                                    <td class="px-3 wrap-column">
                                        <div>
                                            {{ item.device_user_id ? item.device_user_id : '-' }}
                                        </div>
                                    </td>
                                    <td class="px-3">
                                        <b-dropdown size="sm" variant="link"
                                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                            no-caret right no-flip>
                                            <template v-slot:button-content>
                                                <slot>
                                                    <span>
                                                        <i class="flaticon-more-1"></i>
                                                    </span>
                                                </slot>
                                            </template>
                                            <!--begin::Navigation-->
                                            <div class="navi navi-hover">
                                                <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('attendance', 'edit')">
                                                    <a href="#" class="navi-link" @click="editAttendance(item.id)">
                                                        <span class="navi-icon">
                                                            <i class="flaticon-edit"></i>
                                                        </span>
                                                        <span class="navi-text"> Edit </span>
                                                    </a>
                                                </b-dropdown-text>
                                                <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('attendance', 'delete')">
                                                    <a href="#" class="navi-link" @click="deleteAttendance(item.id)">
                                                        <span class="navi-icon">
                                                            <i class="flaticon-delete"></i>
                                                        </span>
                                                        <span class="navi-text"> Delete </span>
                                                    </a>
                                                </b-dropdown-text>
                                            </div>
                                        </b-dropdown>
                                    </td>
                                </tr>
                                <tr v-if="generalAttendances.length == 0">
                                    <td class="text-center" colspan="7">Data not available</td>
                                </tr>
                                </tbody>
                            </table>
                            <b-pagination 
                                v-show="generalAttendances.length > 0" 
                                @input="searchAttendanceReports" 
                                class="pull-right mt-7" 
                                v-model="page"
                                :total-rows="total" 
                                :per-page="perPage" 
                                first-number 
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <create-and-update
            ref="attendance"
            @refresh="searchAttendanceReports"
        ></create-and-update>

      <v-dialog v-model="dialog" max-width="600">
        <v-card>
          <v-toolbar dark>
            <v-card-title class="headline">
              {{userInfo.rfid_id ? "Update" : "Add"}} RFID
              <hr>
            </v-card-title>

            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeDialog">
                <i class="fas fa-times"></i>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                  <p class="mb-5">RFID card can be found on the card or scan card to obtain the number on the card.</p>
                <v-text-field
                    outlined
                    dense
                    v-model="userInfo.rfid_id"
                    type="number"
                    label="RFID card number"
                    clearable
                    :error="$v.userInfo.rfid_id.$error"
                >
                </v-text-field>
                <span class="text-danger" v-if="$v.userInfo.rfid_id.$error">This information is required</span>
              </v-col>

              <v-col cols="12" md="12" v-if="!userInfo.is_rfid_expiry">
                <v-menu
                    ref="menu"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="userInfo.rfid_expiry_date"
                        :error="$v.userInfo.rfid_expiry_date.$error"
                        label="RFID card expiry date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        clearable
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="userInfo.rfid_expiry_date"
                      no-title
                      scrollable
                      :min="today"
                      @input="menu2 = false"
                  >
                  </v-date-picker>
                </v-menu>
                <span class="text-danger" v-if="$v.userInfo.rfid_expiry_date.$error">This information is required</span>
              </v-col>

              <v-col cols="12" md="12">
                <v-switch
                    outlined
                    dense
                    @change="resetRFIDCard"
                    v-model="userInfo.is_rfid_expiry"
                    :label="userInfo.is_rfid_expiry ? 'Expired' : 'Not expired'"
                >
                </v-switch>
              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                depressed
                @click="closeDialog"
                class="text-gray btn btn-standard"
            >
              Cancel
            </v-btn>
            <v-btn
                depressed
                :loading="isBusy"
                @click="saveRFID"
                class="text-white ml-2 btn btn-primary"
                v-if="checkIsAccessible('rfid-card', 'create') || checkIsAccessible('rfid-card', 'edit')"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import CreateAndUpdate from "./CreateAndUpdate";
import UserService from "@/core/services/user/UserService";
import ProgramService from "@/core/services/level/program/ProgramService";
import LevelService from "@/core/services/level/LevelService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GeneralAttendanceService from "@/core/services/general-attendance/GeneralAttendanceService";

const generalAttendance = new GeneralAttendanceService()
const programService = new ProgramService();
const levelService = new LevelService()
const gradeLevelService = new GradeLevelService();
const academicYearService = new AcademicYearService();
const academicClassService = new AcademicClassService();
const userService = new UserService();

export default {
    name:"GeneralAttendance",
    props: ['user'],
    components: {
        CreateAndUpdate
    },
    validations: {
      userInfo: {
        rfid_id: { required: requiredIf(function () {
            return !this.userInfo.is_rfid_expiry
          })
        },
        rfid_expiry_date: { required: requiredIf(function () {
            return !this.userInfo.is_rfid_expiry
          })
        },
      }
    },
    data() {
        return {
            loading: false,
            isBusy: false,
            dialog: false,
            menu: false,
            menu1: false,
            menu2: false,
            userInfo: {
              rfid_id: '',
              is_rfid_expiry: false,
              rfid_expiry_date: '',
            },
            today: new Date().toISOString().substr(0, 10),
            search:{
                user: '',
                subject: '',
                entry_date: '',
                exit_date: '',
                type: '',
                user_type: '',
                program_id: '',
                academic_year_id: '',
                level_id: '',
                grade_id: '',
                class_id: '',
                users_only: ['teacher','student','staff']
            },
            generalAttendances: [],
            isExportingCsv: false,
            page: null,
            perPage: null,
            total: null,
            reportIntervals:[
                {
                    name: 'Select date range',
                    value: 'daily'
                },
                {
                    name: 'Today',
                    value: 'daily'
                },
                {
                    name: 'This week',
                    value: 'weekly'
                },
                {
                    name: 'Monthly',
                    value: 'monthly'
                },
            ],
            types:[
                {
                    name: 'General attendance',
                    value: 'attendance_general'
                },
                {
                    name: 'Bus attendance',
                    value: 'attendance_bus'
                },
                {
                    name: 'Lunch attendance',
                    value: 'attendance_lunch'
                }
            ],
            userTypes:[
                {
                    name: 'Student',
                    value: 'student'
                },
                {
                    name: 'Teacher',
                    value: 'teacher'
                },
                {
                    name: 'Staff',
                    value: 'staff'
                }
            ],
            programs: [],
            levels: [],
            gradesLevels: [],
            academicClasses: [],
            academicYears: [],
        }
    },
    methods: {
        addRFID(){
          this.dialog = true;
          this.userInfo = this.user;
        },

        resetRFIDCard() {
          this.$v.$reset();
          this.userInfo.rfid_id = '';
          this.userInfo.rfid_expiry_date = '';
        },

        saveRFID(){
          this.$v.userInfo.$touch();
          if (this.$v.userInfo.$error) {
            setTimeout(() => {
              this.$v.userInfo.$reset();
            }, 3000);
          } else {
              this.isBusy = true;
              userService.update(this.user.id, this.userInfo).then(response => {
                this.isBusy = false;
                this.closeDialog();
                this.$emit('refresh');
                this.$snotify.success("Update Successfully");
              }).catch(() => {
                this.isBusy = false;
              })
          }
        },

        closeDialog() {
          this.$v.$reset();
          this.userInfo.rfid_id = null;
          this.userInfo.rfid_expiry_date = null;
          this.userInfo.is_rfid_expiry = false;
          this.dialog = false;
        },

        searchAttendanceReports(){
            this.loading = true;

            generalAttendance
            .paginate(this.search, this.page)
            .then((res) => {
                this.generalAttendances = res.data.data;
                this.page = res.data.meta.current_page;
                this.total = res.data.meta.total;
                this.perPage = res.data.meta.per_page;
                this.loading = false;
            })
            .catch((err) => {

            });
        },

        addAttendance(){
            this.$refs['attendance'].showModal();
        },

        editAttendance(attendanceId){
            this.$refs['attendance'].showModal(attendanceId);
        },

        deleteAttendance(attendanceId){
            this.$confirm({
                message: `Are you sure you want to delete?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        generalAttendance
                        .delete(attendanceId)
                        .then(response => {
                            this.$snotify.success('Successfully deleted')
                            this.searchAttendanceReports();
                        })
                        .catch(error => {
                        });
                    }
                }
            });
        },

        exportCsv(){
            this.isExportingCsv = true;

            generalAttendance
            .exportCsv(this.search)
            .then(response => {
                this.$snotify.success('Export has been started. Once completed you can view  on notification.');
            })
            .catch(error => {

            })
            .finally(() => {
                this.isExportingCsv = false;
            })
        },

        getLevels() {
            levelService.all().then(response => {
                this.levels = response.data;
                if (this.search.id && this.search.level_id) {
                    this.getPrograms();
                }
            });
        },

        getPrograms() {
            this.search.program_id = null;
            this.programs = [];
            this.search.grade_id = null;
            this.gradesLevels = [];
            this.search.class_id = null
            this.academicClasses = [];


            programService
            .getAllByLevelId(this.search.level_id)
            .then(response => {
                this.programs = response.data;
            });
        },

        getGrades() {
            this.search.grade_id = null;
            this.gradesLevels = [];
            this.search.class_id = null
            this.academicClasses = [];

            gradeLevelService
            .getByProgram(this.search.program_id)
            .then(response => {
                this.gradesLevels = response.data;
            });
        },

        getAcademicClasses() {
            this.search.class_id = null
            this.academicClasses = [];
            
            academicClassService.getByProgramLevel(this.search.academic_year_id, this.search.program_id, this.search.grade_id).then(response => {
                this.academicClasses = response.data;
            });
        },

        getAcademicYears() {
            academicYearService.all().then(response => {
                this.academicYears = response.data;
            });
        },
        studentSummary(student_id){
            this.$router.push(
                {
                name: 'students-summary',
                params: {id: student_id}
                }
            )
        },
    },
    mounted(){
        if(this.user) {
          this.search.user_id = this.user.id;
          this.search.users_only = [];
          this.search.user_type = this.user.type.toString();
        }
        this.getAcademicYears()
        this.getLevels();
    }
}
</script>

<style scoped>
    .wrap-column{
        max-width: 270px !important; 
        white-space: pre-wrap;
    }
</style>