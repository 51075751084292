<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        {{ `${edit ? 'Update' : "Add"}  check-in/out` }}
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="resetForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                @change="selectUser"
                                v-model="selectedUser"
                                outlined
                                dense
                                :items="users"
                                hide-selected
                                item-text="display_text"
                                item-value="id"
                                :search-input.sync="userSearch"
                                @input="attendance.user_id = $event !== null ? $event : ''" 
                                :loading="isUserLoading"
                                clearable
                            >
                            <template v-slot:label>
                                <span class="text-danger"></span> Search by name, email or CRN
                            </template>
                            <template v-slot:no-data>
                                <div class="no-data-auto-complete text-center">No data available to display.</div>
                            </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.attendance.user_id.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-select
                                outlined
                                dense
                                v-model="attendance.type"
                                label="Attendance type"
                                :items="types"
                                item-text="name"
                                item-value="value"
                            >
                            </v-select>
                            <span class="text-danger" v-if="$v.attendance.type.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-datetime-picker 
                                outlined
                                dense
                                ref="dateTime"
                                label="Entry time"
                                :text-field-props="textFieldProps"	
                                v-model="attendance.entry_timestamp"
                            >
                            </v-datetime-picker>
                            <span class="text-danger" v-if="$v.attendance.entry_timestamp.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-datetime-picker 
                                outlined
                                dense
                                ref="dateTime1"
                                label="Exit time"
                                :text-field-props="textFieldProps"	
                                v-model="attendance.exit_timestamp"
                            >
                            </v-datetime-picker>
                            <span class="text-danger" v-if="$v.attendance.exit_timestamp.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn 
                                    depressed 
                                    @click="resetForm" 
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>
                                <!-- <v-btn 
                                    v-if="checkIsAccessible('gate-pass', 'create')" 
                                    depressed 
                                    :loading="isBusy"
                                    @click="createOrUpdate" 
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn> -->
                                <v-btn 
                                    depressed 
                                    :loading="isBusy"
                                    @click="createOrUpdate" 
                                    class="text-white ml-2 btn btn-primary"
                                    v-if="checkIsAccessible('attendance', 'create') || checkIsAccessible('attendance', 'edit')"
                                >
                                  {{ edit ? "Update" : "Save" }}
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import GeneralAttendanceService from "@/core/services/general-attendance/GeneralAttendanceService";
import UserService from "@/core/services/user/UserService";

const generalAttendance = new GeneralAttendanceService();
const user = new UserService();

export default {
    validations: {
        attendance: {
            user_id: {required},
            type: {required},
            entry_timestamp: {required},
            exit_timestamp: {},
        }
    },
    data() {
        return {
            users: [],
            dialog: false,
            edit: false,
            isBusy: false,
            attendance: {
                user_id: null,
                type: '',
                entry_timestamp: null,
                exit_timestamp: null,
            },
            isUserLoading: false,
            userSearch: '',
            textFieldProps:{
                outlined: true,
                dense: true,
            },
            types:[
                {
                    name: 'General attendance',
                    value: 'attendance_general'
                },
                {
                    name: 'Bus attendance',
                    value: 'attendance_bus'
                },
                {
                    name: 'Lunch attendance',
                    value: 'attendance_lunch'
                }
            ],
        };
    },
    methods: {
        selectedUser(val)
        {
            if(!val){
                this.attendance.user_id ='';
            }
        },
        selectUser()
        {
            if(this.selectedUser && this.selectedUser != null){
                this.attendance.user_id = this.selectedUser;
            }else{
                this.attendance.user_id = "";
            }
        },
        showModal(id = null) {
            if (id) {
                this.edit = true
                this.getGeneralAttendance(id)
            }
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
        },
        getGeneralAttendance(id) {
            generalAttendance
            .show(id)
            .then((response) => {
                this.attendance = response.data.generalAttendance;
                if(this.attendance.user_id){
                    this.searchUser(this.attendance.user_email)
                }
                if(response.data.generalAttendance.entry_timestamp_formatted){
                    let entryTimeStamp = Date.parse(this.attendance.entry_timestamp_formatted);
                    let entryDateTimeObject = new Date(entryTimeStamp);
                    this.attendance.entry_timestamp = entryDateTimeObject;
                }
                if(response.data.generalAttendance.exit_timestamp_formatted){
                    let exitTimeStamp = Date.parse(this.attendance.exit_timestamp_formatted);
                    let exitDateTimeObject = new Date(exitTimeStamp);
                    this.attendance.exit_timestamp = exitDateTimeObject;
                }
            })
            .catch(err => {

            })
            .finally(() => {

            })
        },
        searchUser(userEmail){
            this.isUserLoading = true;
            
            const types = ['student', 'teacher', 'staff']; 

            let data = {
                val: userEmail,
                types: types
            };

            user
            .getByTypes(data)
            .then((response) => {
                    response.data.users.map((user) => {
                    let data = user;
                    data.display_text =
                        user.full_name +
                        " | " +
                        user.personal_id + 
                        " | " +
                        user.email;
                    
                    this.users.push(data);
                });
                this.selectedUser = response.data.users[0];
            })
            .catch((err) => {

            })
            .finally(() => (this.isUserLoading = false));
            
        },
        createOrUpdate() {
            this.$v.attendance.$touch();
            if (this.$v.attendance.$error) {
                setTimeout(() => {
                    this.$v.attendance.$reset();
                }, 3000);
            } else {
                if (this.edit) {
                    this.updateAttendance();
                } else {
                    this.createAttendance();
                }
            }
        },
        updateAttendance() {
            this.isBusy = true;
        
            this.formatEntryDate();
            this.formatExitDate();

            generalAttendance
            .update(this.attendance.id, this.attendance)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.resetForm();
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        createAttendance() {
            this.isBusy = true;
            
            this.formatEntryDate();
            this.formatExitDate();
            
            generalAttendance
            .store(this.attendance)
            .then((response) => {
                this.isBusy = false;
                this.resetForm();
                this.$snotify.success("Information added");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.attendance.$reset();
            this.$refs.dateTime.date = '';
            this.$refs.dateTime1.date = '';

            this.edit = false;
            this.attendance = {
                user_id: null,
                type: '',
                entry_timestamp: null,
                exit_timestamp: null,
            };
            this.userSearch = null;          


            this.users = [];
            this.dialog = false;
            this.isBusy = false;
            this.isUserLoading = false;

            this.$emit("refresh");
        },

        formatEntryDate(){
            if(this.attendance.entry_timestamp){
                var fullDate = new Date(this.attendance.entry_timestamp);
                const year = fullDate.getFullYear();
                const month = parseInt(fullDate.getMonth())+parseInt(1);
                const day = fullDate.getDate();
                const hour = fullDate.getHours();
                const minute = fullDate.getMinutes();
                
                const entryDateTime = year+'-'+month+'-'+day+' '+hour+':'+minute+':'+'00';
                this.attendance.entry_timestamp = null;
                this.attendance.entry_timestamp = entryDateTime;
            }
            return;
        },  

        formatExitDate(){
            if(this.attendance.exit_timestamp){
                var fullDate = new Date(this.attendance.exit_timestamp);
                const year = fullDate.getFullYear();
                const month = parseInt(fullDate.getMonth())+parseInt(1);
                const day = fullDate.getDate();
                const hour = fullDate.getHours();
                const minute = fullDate.getMinutes();
                
                const exitDateTime = year+'-'+month+'-'+day+' '+hour+':'+minute+':'+'00';
                this.attendance.exit_timestamp = null;
                this.attendance.exit_timestamp = exitDateTime;
            }
            return ;
        }

    },
    watch:{
        userSearch(val) {
            this.isUserLoading = true;
            
            const types = ['student', 'teacher', 'staff']; 

            let data = {
                val: val,
                types: types
            };

            user
            .getByTypes(data)
            .then((response) => {
                    response.data.users.map((user) => {
                    let data = user;
                    data.display_text =
                        user.full_name +
                        " | " +
                        user.personal_id + 
                        " | " +
                        user.email
                    this.users.push(data);
                });
            })
            .catch((err) => {

            })
            .finally(() => (this.isUserLoading = false));
      },
    }
}
</script>
